import React, { useState } from "react"
import "./CalendlyBanner.scss"
import { Row, Col, Modal, Button, Container } from "react-bootstrap"
import calendly from "../../images/calendlyUS.svg"


const CalendlyBanner = ({ strapiData1, strapiData2 }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div className="schedule-section">
        <div className="hero_header">
          <Container className="global_container">
            <h2
              className="hero_title"
              dangerouslySetInnerHTML={{ __html: strapiData1?.title }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strapiData1?.subTitle,
              }}
              className="hero_sub_title"
            />
          </Container>
        </div>
        <Container className="global_container">
          <Row className="top-section align-items-center">
            <Col lg={5} md={12} className="text-section">
              <h2 dangerouslySetInnerHTML={{ __html: strapiData2?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData2?.subTitle,
                }}
              />
            </Col>
            <Col
              lg={7}
              md={12}
              className="calendar-section d-flex justify-content-center"
            >
              <div className="calendly-img">
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img src={calendly} alt="calendly" style={{ display: 'block' }} />
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    opacity: 0.023,
                    borderRadius: '8px'
                  }}></div>
                </div>
                <a
                  className="calendly-link"
                  href="https://calendly.com/invo/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="primary" className="schedule-button">
                    Schedule a Meeting
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <img
          className="curve"
          src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
          alt="curve"
        />
      </div>
    </>
  )
}

export default CalendlyBanner
